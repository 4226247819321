import React from "react";
import { Typography } from "@material-ui/core";
import styles from "./header.module.scss";
import { Link } from "react-router-dom";
import csmWhiteNewText from "../../assets/images/csmWhiteNewText.png";
import { useMediaQuery } from "@mui/material";
const Header = (props) => {

  const isMobile = useMediaQuery('(max-width:768px)');  

  return  (
    <div className={styles.headerWrap}>
      {isMobile ? (
        <>
          <div className={`d-flex flex-center`}>
            <Link to="/" className={styles.logowrap}>
              <Typography component="h6" variant="h6" className={"pr-2"}>
                <img src={csmWhiteNewText} alt="PROTeam Admin Logo" className="cms-logo" />
              </Typography>
            </Link>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex">
            <Link to="/" className={styles.logowrap}>
              <Typography component="h6" variant="h6" className={"pr-2"}>
                <img src={csmWhiteNewText} alt="PROTeam Admin Logo" className="cms-logo" />
              </Typography>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default Header;